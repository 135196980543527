.westSlider1 {
    background-image: linear-gradient(rgba(0, 0, 0, 0.196),
            rgba(0, 14, 23, 0.8)),
        url("../home/assets/Picture1.jpg");

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    height: 44rem;
}

.westSlider2 {
    background-image: linear-gradient(rgba(0, 0, 0, 0.196),
            rgba(0, 14, 23, 0.8)),
        url("../home/assets/Picture1.jpg");

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    height: 44rem;
}

.westSlider3 {
    background-image: linear-gradient(rgba(0, 0, 0, 0.196),
            rgba(0, 14, 23, 0.8)),
        url("../home/assets/Picture1.jpg");

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    height: 44rem;
}

.westSlider4 {
    background-image: linear-gradient(rgba(0, 0, 0, 0.196),
            rgba(0, 14, 23, 0.8)),
        url("../home/assets/Picture1.jpg");

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    height: 44rem;
}

.westSlider5 {
    background-image: linear-gradient(rgba(0, 0, 0, 0.196),
            rgba(0, 14, 23, 0.8)),
        url("../home/assets/Picture1.jpg");

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    height: 44rem;
}