

.a{
    padding: 5rem;
    overflow-x: hidden;
}
.a1{
    display: flex;
    justify-content: center;
    align-items: center;

}

.a11,.a12{
    width: 50%;
}


.a2{
    padding-top: 3rem;;
display: flex;
flex-direction: column;


}


.a2x{
        display:flex;
gap:2rem;
    justify-content: center;
    align-items: center;
}

.a21{
    display: flex;
    flex-direction: column;
}


.a2x{
width: 100%;
}

.a21d{
    width:30%;
}

.a21i{
    border-radius:2rem;
    height:25rem;
}

.a22{
    display: flex;
    flex-direction: column;
    align-items:left;
    padding: 2rem;

}

