.westBanner {
    width: 100%;
    height: 160vh;
    /* background-image: url("./assets/sumafinal.jpg"); */
    /* background-size: cover;
    background-position: center;
    background-repeat: no-repeat; */


}

.westBanner {
    background-image: url("./assets/hand5.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 40rem;
}

.bannerImg {
    width: 100%;
    height: 100%;
}