.Proudcontain {
    /* background-image: linear-gradient(rgba(255, 255, 255, 0.845),
            rgba(255, 255, 255, 0.863)),
        url("./proud1.jpg"); */
    background-image: url("./proud2.webp");

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.background-container {
    position: relative;
    overflow: hidden;
    /* Ensures that pseudo-element does not overflow */
    z-index: 1;
    /* Ensures content is above pseudo-element */
    padding: 20px;
    /* Example padding for content */
    color: white;
    /* Example text color */
}

.background-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('./proud2.webp');
    /* Replace with your image URL */
    background-size: cover;
    background-position: center;
    opacity: 0.4;
    /* Adjust opacity as needed */
    z-index: -1;
    /* Place it behind the content */
}

.proudHead {
    background: -webkit-linear-gradient(left, #00a1d6, #484848);
    background: -o-linear-gradient(right, #00a1d6, #484848);
    background: -moz-linear-gradient(right, #00a1d6, #484848);
    background: linear-gradient(to right, #00a1d6, #484848);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    /* background: -webkit-linear-gradient(#00a1d6, #484848);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
}