.black-bg {
    background-color: #004860;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    position: relative;
}

.child {
    width: 40vw;
    height: 50vh;
    background-color: white;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: width 0.5s, height 0.5s;
    /* background-image: linear-gradient(rgba(1, 1, 1, 0.244), rgba(0, 0, 0, 0.646)), url("../home/assets/t1.png"); */
    /* background-image: linear-gradient(rgba(1, 1, 1, 0.244), rgba(0, 0, 0, 0.646)), url("../../pages/assets/sumafinal.jpg"); */
    background-image: url("../../pages/assets/sumafinal.jpg");
    opacity: 0.7;
    /* background-size: contain; */
    /* Ensure the entire image is contained within the div */
    background-position: center;
    /* Center the background image */
    background-repeat: no-repeat;
    background-size: cover;

    /* max-width: 100%;
    max-height: 100%;
    margin: auto;
    display: block; */
}



.child.scrolled {
    width: 100vw;
    height: 100vh;
}

.text-white {
    color: white;
}

.text-black {
    color: black;
}