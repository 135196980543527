.wid {
    width: calc(100% / 6);
    /* 2/12 width */
    transition: width 1s ease;
    /* Transition effect for width change */
}

.hovered {
    width: calc(100% / 2);
    /* 6/12 width */
}