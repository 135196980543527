.whyBg {
    background-image: linear-gradient(rgba(2, 80, 132, 0.8),
            rgba(0, 14, 23, 0.8)),
        url("../assets/about2.jpg");

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    padding:5rem
}