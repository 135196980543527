@keyframes fadeInOut {
    0% {
        opacity: 0;
        /* Start transparent */
    }

    50% {
        opacity: 1;
        /* Fade in */
    }

    100% {
        opacity: 0;
        /* Fade out */
    }
}

.animate-ring {
    animation: fadeInOut 2s ease-in-out infinite;
    /* Adjust timing as needed */
}