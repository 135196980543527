/* Burger.css */
.bm-burger-button {
  position: fixed;
  width: 39px;
  height: 25px;
  right: 36px;
  top: 15px;
}



.custom-burger-icon {
  width: 30px;
  height: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.custom-burger-icon span {
  display: block;
  height: 3px;
  width: 100%;
  background-color: #333;
  border-radius: 3px;
  transition: all 0.3s ease;
}

.bm-cross-button {
  height: 24px;
  width: 24px;
}

.bm-cross {
  background: #bdc3c7;
}

.bm-menu {
  background: #f8f9fa;
  padding: 2.5em 1.5em 0;
  font-size: 1rem;

}

.bm-morph-shape {
  fill: #373a47;
}

.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

.menu-header {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
  text-align: center;
}

.menu-item {
  display: block;
  text-decoration: none;
  color: #333;
  padding: 15px 0;
  border-bottom: 1px solid #e9ecef;
  transition: all 0.3s ease;
}

.menu-item:hover {
  color: #007bff;
  background-color: #e9ecef;
  padding-left: 10px;
}

.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.project-dropdown {
  cursor: pointer;
}

.dropdown-icon {
  float: right;
  font-size: 1.2em;
}

.dropdown {
  display: none;
  padding-left: 20px;
}

.dropdown.show {
  display: block;
}

.sub-item {
  font-size: 0.9em;
  padding: 10px 0;
}

.sub-item:hover {
  background-color: transparent;
}