.holdVideo {
    color: black;
    /* margin-top: -1rem; */
    width: 100%;
    overflow: hidden;
}

.vid {
    width: 100%;
    height: 100%;
}