.heroCardBg2 {
    /* background-image: linear-gradient(rgba(254, 254, 254, 0.845),
            rgba(61, 61, 61, 0.863)),
        url("./assets/cardBg.jpg"); */

    background-image: url(./assets/cardBg.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

}

.heroCardBg {
    background-image: linear-gradient(rgba(42, 68, 101, 0.954),
            rgba(42, 68, 101, 0.557)),
        url("./assets/bg2.webp");


    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}