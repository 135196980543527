.HeroDivBg {
    background-image: url("./assets/hero5.jpg");

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    height: 50rem;
    width: 100%;
}

.holdVideo {
    color: black;
    /* margin-top: -1rem; */
    width: 100%;
    overflow: hidden;
}

.vid {
    width: 100%;
    height: 100%;
}