.a{
    background-color: rgb(255, 243, 245);
    display: flex;
    justify-content: center;
    align-items: center;
   /* padding:3rem;*/
overflow-x: hidden;
    position: relative;
}


.b{

display: flex;
    background-color: white;
    width: 100%;
   /* border-radius: 2rem;*/
}



.b1{
    width: 40%;
    padding: 3rem;
    padding-top: 10rem;
}


.b2{
       width: 30%;
    padding: 5rem;
display: flex;
flex-direction: column;
justify-content: space-around;
align-items: center;
gap:2rem;
overflow:visible;
margin-left: -3rem;

}



.ig1{

        height: 25rem;
    width:25rem;
border-top-left-radius: 8rem;
border-top-right-radius: 8rem;
margin-top: -12rem;
transform: scale(0.9);
transition: transform ease-in-out 1s;

}


.ig2{
        height: 15rem;
    width:15rem;
margin-top: -4rem;
transform: scale(0.9);
transition: transform ease-in-out 1s;
}

.ig3{

        height: 15rem;
    width:25rem;
border-top-left-radius: 8rem;
border-top-right-radius: 8rem;
border-bottom-right-radius: 8rem;
border-bottom-left-radius: 8rem;
transition: transform ease-in-out 1s;
background: url('./asset/SumaHouse.JPG');
background-repeat: no-repeat;
background-position:top center;
background-size: cover;
transform: scale(0.9);
margin-top: -2rem;
}


.ig1:hover,.ig2:hover,.ig3:hover{
    transform:scale(1)
}