.slideCont {
    width: 100%;
}



.abtSlider4 {
    width: 100%;
    height: 33rem;
    background-image:
        linear-gradient(to top, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.1) 10%, rgba(0, 0, 0, 0) 100%),
        linear-gradient(rgba(1, 1, 1, 0.11), rgba(15, 15, 15, 0.685)),
        url("../assets/MODIBAUG.webp");

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

}

.abtSlider3 {
    width: 100%;
    height: 33rem;
    background-image:
        linear-gradient(to top, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.1) 10%, rgba(0, 0, 0, 0) 100%),
        linear-gradient(rgba(1, 1, 1, 0.11), rgba(15, 15, 15, 0.05)),
        url("../assets/5.webp");

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

}

.abtSlider6 {
    width: 100%;
    height: 33rem;
    background-image:
        linear-gradient(to top, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.1) 10%, rgba(0, 0, 0, 0) 100%),
        linear-gradient(rgba(1, 1, 1, 0.11), rgba(15, 15, 15, 0.05)),
        url("../assets/WestEnd.webp");

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

}

.abtSlider1 {
    width: 100%;
    height: 33rem;
    background-image:
        linear-gradient(to top, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.1) 10%, rgba(0, 0, 0, 0) 100%),
        linear-gradient(rgba(1, 1, 1, 0.11), rgba(15, 15, 15, 0.05)),
        url("../assets/6.jpeg");

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

}


.abtSlider5 {
    width: 100%;
    height: 33rem;
    background-image:
        linear-gradient(to top, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.1) 10%, rgba(0, 0, 0, 0) 100%),
        linear-gradient(rgba(1, 1, 1, 0.11), rgba(15, 15, 15, 0.05)),
        url("./DTC.webp");

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

}



.abtSlider11 {
    width: 100%;
    height: 33rem;
    background-image:
        linear-gradient(to top, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.1) 10%, rgba(0, 0, 0, 0) 100%),
        linear-gradient(rgba(1, 1, 1, 0.11), rgba(15, 15, 15, 0.05)),
        url("../assets/westendCn3.webp");

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

}