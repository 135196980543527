.a{
    background-color: rgb(255, 243, 245);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding:3rem;
    margin-top: -2rem;
    position: relative;
    overflow-x: hidden;
}


.b{

display: flex;
flex-direction: column;
    background-color: white;
    width: 100%;
    border-radius: 2rem;
    padding:2rem
}

.hrc{
    height:0.1rem;
    background-color: black;
}

.c{
    display: flex;
    padding: 2rem;

}

.c1{
width:40%;
display:flex;
gap:1rem
}


.imgc{
    height: 12rem;
    width: 12rem;
    border-radius: 2rem;
}


.c12{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}

.c2{
width:40%;
padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.c3{
width:20%;
display: flex;
justify-content: center;
align-items: center;
}

.btn{
    border-radius: 50%;
    height: 8rem;
    width: 8rem;
    border: solid 1px black;
display: flex;
align-items: center;
justify-content: center;
}

.btn:hover{
    background-color: black;
    color: white;
}