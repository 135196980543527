.delpopup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.delpopup {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

/* Apply backdrop blur effect */
.popup-container {
    backdrop-filter: blur(5px);
}

.popBtnDiv {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
    margin-top: 1rem;
}

.delpopBtn {
    cursor: pointer;
}

.popBtn2 {
    width: 7rem;
    height: 2.2rem;
    background-color: green;
    border-radius: 0.5rem;
    border: none;
    color: white;
}

.popBtn:hover {
    box-shadow: 0 4px 8px 0 rgb(206, 206, 206), 0 6px 20px 0 rgb(209, 209, 209);
    cursor: pointer;
}