@import url('https://fonts.googleapis.com/css2?family=Cambo&display=swap');


.contain {
    margin-top: 4rem;
    /* clip-path: polygon(0 0, 100% 0, 100% 94%, 0% 100%); */
    background-color: #004860db;
    height: 360vh;
    overflow: hidden;

}

.welcome {
    font-family: "Cambo", serif;
    color: white;
    font-size: 5rem;
    font-weight: 700;
    margin-left: 2rem;
    margin-top: 6rem;
    display: block;
    line-height: 120%;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    /* Adjust values as needed */

}

.slideContainer {
    height: 150vh;
    margin-bottom: 25rem;
}

.slide {
    width: 100vw;
    height: 210vh;
    background-image:
        /* linear-gradient(to top, rgba(0, 0, 0, 0.712) 0%, rgba(0, 0, 0, 0.1) 10%, rgba(0, 0, 0, 0) 100%),
        linear-gradient(rgba(1, 1, 1, 0.11), rgba(15, 15, 15, 0.653)), */
        url("./assets/WestEndCrown1.jpg");

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 15rem;

}

.slide2 {
    width: 100vw;
    height: 220vh;
    background-image:
        /* linear-gradient(to top, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.1) 10%, rgba(0, 0, 0, 0) 100%),
        linear-gradient(rgba(1, 1, 1, 0.11), rgba(15, 15, 15, 0.685)), */
        url("./assets/westendMall1.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

}

/* .slide2 {
    width: 100vw;
    height: 100vh;
    background-image: linear-gradient(rgba(1, 1, 1, 0.11),
            rgba(15, 15, 15, 0.815)),
        url("./assets/westendMall1.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

} */


.slide3 {
    width: 100vw;
    height: 220vh;
    background-image:
        /* linear-gradient(to top, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.1) 10%, rgba(0, 0, 0, 0) 100%),
        linear-gradient(rgba(1, 1, 1, 0.11), rgba(15, 15, 15, 0.685)), */
        url("./assets/vans.jpg");


    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

}


.spa {
    width: fit-content;
    margin-top: 1rem;
    margin-left: 5rem;
    padding: 3rem;
    background-color: rgba(0, 0, 0, 0.208);
    /* background-color: rgba(57, 57, 57, 0.183); */
}