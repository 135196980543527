.contain {
    width: 100vw;

    /* overflow: hidden; */

}

.slider-container {
    position: relative;
}

.slick-dots {
    bottom: -30px;
    /* Adjust based on your design needs */
}