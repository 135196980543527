@tailwind base;
@tailwind components;
@tailwind utilities;



@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans:ital,wght@0,300..800;1,300..800&display=swap');


@font-face {
    font-family: 'MerriweatherSans';
    src: url('../fonts/MerriweatherSans.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}


.meri {
    font-family: "Merriweather Sans", sans-serif;

}

@font-face {
    font-family: 'Garet-Book';
    src: url('../fonts/Garet-Book.woff2') format('woff2'),
        url('../fonts/Garet-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}