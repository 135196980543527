.modiHero {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}



/*  */
.mcontainer {
    width: 100%;
    padding: 3rem;
}

.mtop1 {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 3rem;
    align-items: flex-start;
}

.mtop1 .mleft {
    width: 60%;
    height: 30rem;
    position: relative;
    display: flex;
    flex-direction: column;
}

.mtop1 .mleft .mleftTop {
    position: relative;
    width: 100%;
    height: 25rem;
    overflow: hidden;
}

.mtop1 .mleft .mleftTop img {
    position: relative;
    width: 100%;
    height: 100%;
    aspect-ratio: 3/2;
    object-fit: contain;
    overflow: hidden;
    position: absolute;
}

.mcoverImages {
    display: flex;
    width: 100%;
    flex-direction: row;
    overflow-x: scroll;
}

.mcoverImages img {
    width: 4;
    height: 3rem;
    margin: .3rem;
}

.mtop1 .mright {
    width: 35%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.mtop1 .mright .mstar {
    width: 8rem;
}

.mrow {
    display: flex;
    flex-direction: row;
    gap: 2rem;
}

.mbtn {
    background-color: rgb(17, 168, 62);
    border: none;
    width: 100%;
    padding: .5rem 2rem;
    border-radius: .5rem;
    font-weight: 700;
    letter-spacing: 1px;
    color: white;
    text-decoration: none;
}

.mtop2 {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.mtop2 .info {
    width: 13rem;
    margin: .1rem;
    padding-bottom: .5rem;
    border-bottom: 1px solid rgb(193, 193, 193);
}

.mtop2 .info2 {
    width: 13rem;
    margin: .1rem;
    padding-bottom: .5rem;
    border-bottom: 1px solid rgb(193, 193, 193);
    font-size: .9rem;
    margin-left: -.5rem;
}


.mtop2 .info,
.mdetailed p {
    font-size: .9rem;
}

.mtop2 .detailed {
    width: 29rem;
    margin: .5rem;
    padding-bottom: .5rem;
    border-bottom: 1px solid rgb(193, 193, 193);
}

.mtabs {
    margin: 2rem 0;
}

.mrates {
    margin: 2rem 0;
}

.mrates table {
    border: 1px solid black;
    border-collapse: collapse;
}

.mrates .card2 tr,
.mrates .card2 th,
.mrates .card2 td {
    border: 1px solid black;
}

.mlogimg img {
    width: 3rem;
}

.mpsize {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    padding: .3rem;
}

.mbrochureContainer {
    position: relative;
    width: 100%;
    height: 15rem;
    overflow: hidden;
    object-fit: contain;
    object-position: center;
}

.mbrochureContainer .innerBrochureContainer {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.mbrochureContainer img {
    width: 100%;
    height: 100%;
    aspect-ratio: 3/2;
    object-fit: contain;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.398);
    padding: 1rem;
    background: rgb(245, 245, 245);
}

.mlnk {
    text-decoration: none;
    color: black;
}

.msize {
    width: 20rem;
}

.mcard {
    padding: 1rem;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.398);
    border-radius: .5rem;
    margin: 1rem 0;
}

.mcard2 {
    width: 60%;
    padding: 1rem;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.398);
    border-radius: .5rem;
    margin-bottom: 1rem;
    margin-top: 0;
}

.munderline {
    width: 5rem;
    height: 4px;
    border-radius: 2rem;
    background-color: rgb(222, 114, 13);
    margin: .2rem 0;
}

.mrer {
    display: inline-flex;
    gap: 1rem;
}

.mvid {
    aspect-ratio: 3/2;
    object-fit: contain;
}

.mamenity {
    display: flex;
    flex-direction: row;
    gap: 8rem;
    align-items: flex-start;
    text-align: left;
    margin-bottom: 1rem;
}

.mamenityVal {
    width: 70%;
}

/** MEnu TAB **/
.mnav {
    display: flex;
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    top: 5rem;
    z-index: 2;
    height: 60px;
    min-height: 60px;
    width: 100%;
    background-color: #fff;
    padding: 0.4rem;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.398);
}

.mnav__container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    position: relative;
}

.mnav__container__actions {
    display: inline-flex;
    flex-flow: row nowrap;
    padding-left: 50px;
    flex: 1;
}

.mnav__container__actions ul {
    display: flex;
    gap: 1rem;
    font-size: 0.9rem;
    list-style: none;
    padding: 0;
}

.mnav__container__actions li {
    cursor: pointer;
}

.mnav__container__actions Link {
    text-decoration: none;
}

.mnav__container__actions li:hover {
    text-decoration: underline;
}

.mnavbtn {
    background: none;
    border: none;
    font-size: .9rem;
    font-weight: 600;
}