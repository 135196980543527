.contain {
    margin-top: 4rem;
    position: relative;



}

.addHold {
    position: absolute;
    top: 4.375rem;
    background-color: rgb(17, 171, 243);
    display: flex;
    flex-direction: column;
    color: aliceblue;
    width: 29rem;
    height: 21rem;
    margin-left: 45rem;
    padding-top: 1.4rem;
}

.head {
    font-weight: 600;
    margin-left: 2rem;
    margin-bottom: 1rem;
}

.add {
    width: 21rem;
    margin-left: 2rem;
    margin-bottom: 1rem;

}

.no {
    margin-left: 2rem;
    margin-bottom: 1rem;
}

.email {
    margin-left: 2rem;
    margin-bottom: 1rem;
}

.img {
    width: 1rem;
    height: 1rem;
    margin-right: 1rem;
}

.mon {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;

}

@media screen and (max-width:768px) {
    html {
        font-size: 50%;
    }

    .contain {
        width: 100%;

        margin-top: 4rem;
        display: flex;
        flex-direction: row;


    }

    .map {
        width: 95%;
        height: 35rem;
        margin: 1.5rem;
    }

    .addHold {

        top: 36.375rem;
        background-color: rgb(17, 171, 243);
        display: flex;
        flex-direction: column;
        color: aliceblue;
        width: 95%;
        height: 22rem;
        margin: 1.5rem;
        padding-top: 1.4rem;
    }
}