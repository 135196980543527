.conatin {
    margin-top: 8rem;
    margin-bottom: 7rem;
    /* background: linear-gradient(162deg, rgba(255, 255, 255, 0.192) 60%, #0f8dd7 10%); */
    width: 100%;

}

.holder {
    margin-top: 2rem;
    background-image: linear-gradient(rgba(219, 219, 219, 0.553),
            rgba(216, 215, 215, 0.429)),
        url("../assets/about2.jpg");
    padding-top: 5rem;
    padding-bottom: 5rem;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed
}


.outline {
    margin-left: 1rem;
    font-family: "Poppins", sans-serif;
    text-align: center;
    font-size: 5rem;
    margin-right: 1rem;
    font-weight: 900;
    color: black;
    -webkit-text-fill-color: white;
    /* Will override color (regardless of order) */
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #a7a7a7;
    text-shadow: 2px 2px #606060;
}